import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  MenuFilesDisplay,
} from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class SpecialsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Specials"}
              tagline={""}
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[
                {
                  internal: false,
                  text: "Akron Location - (330) 798-0170",
                  link: fishermanBusiness.ctas.phone.link,
                },
                {
                  text: "Cuyahoga Falls Location - (330) 935-4444",
                  link: "tel:+13309354444",
                  internal: false,
                },
              ]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={"100vh"}
              gutter={false}
            />
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            textAlign={"center"}
          >
            <Grid.Column width={"16"}>
              <MenuFilesDisplay
                files={[
                  {
                    url: "https://fisherman.gumlet.io/public/dillysdrivein/Specials_JAN.FEB.pdf",
                    filename: "Specials",
                  },
                ]}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      ctas {
        phone {
          link
        }
      }
    }
    fishermanBusinessWebsitePage(title: { eq: "Specials" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              fluid(maxWidth: 720) {
                aspectRatio
                base64
                src
                srcSet
              }
            }
          }
        }
      }
    }
  }
`;
